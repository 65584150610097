@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #111827;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 150px;
  position: relative;
}

.loader-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loader-block {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 2px;
  background-color: #fff;
  opacity: 45%;
  box-shadow: 0 0 20px #fff;
  animation: loader_562 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

@keyframes loader_562 {
  0% {
    transform: scale(1);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
    opacity: 75%;
  }

  20% {
    transform: scale(1, 2.5);
    box-shadow: 0 0 50px rgba(255, 255, 255, 0.7);
    opacity: 85%;
  }

  40% {
    transform: scale(1);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
    opacity: 95%;
  }
}
